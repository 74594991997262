import { createClient } from '@supabase/supabase-js'

// Development-only values
const supabaseUrl = 'https://yphrbwzawjrggtxtrqus.supabase.co'
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InlwaHJid3phd2pyZ2d0eHRycXVzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzk3MzcwNzUsImV4cCI6MjA1NTMxMzA3NX0.4GyPVhQ9Jp3iFCzWH9lxtlcTzGrpq2a_PYi1PKoL_Vo'

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    storageKey: 'podsoul-auth-token',
    storage: window.localStorage
  }
})