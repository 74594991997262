'use client'

import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Mic, Wand2, Zap, Timer, Share2, BarChart3 } from 'lucide-react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../components/ui/accordion'

const features = [
  {
    icon: Wand2,
    title: 'AI-Powered Script Generation',
    description: 'Transform any topic into engaging podcast scripts with our advanced AI technology.',
    details: [
      'Natural conversational flow between speakers',
      'Automatic research and fact incorporation',
      'Multiple viewpoint generation for balanced discussions',
      'Customizable tone and style options'
    ]
  },
  {
    icon: Mic,
    title: 'Professional Voice Synthesis',
    description: 'Create natural-sounding podcasts with our high-quality voice generation.',
    details: [
      'Multiple voice options and personalities',
      'Emotion and tone control',
      'Natural speech patterns and pauses',
      'Support for multiple languages'
    ]
  },
  {
    icon: Share2,
    title: 'Multi-Platform Optimization',
    description: 'Generate content perfectly formatted for every platform.',
    details: [
      'YouTube-optimized long-form content',
      'TikTok and Instagram Reels short formats',
      'Traditional podcast platform support',
      'Automatic format adaptation'
    ]
  },
  {
    icon: Timer,
    title: 'Rapid Production',
    description: 'Create professional podcasts in minutes instead of hours.',
    details: [
      'Quick script generation',
      'Real-time voice synthesis',
      'Instant platform optimization',
      'Batch content creation'
    ]
  },
  {
    icon: BarChart3,
    title: 'Analytics & Insights',
    description: 'Track performance and optimize your content strategy.',
    details: [
      'Engagement metrics tracking',
      'Platform-specific analytics',
      'Audience retention insights',
      'Content optimization suggestions'
    ]
  },
  {
    icon: Zap,
    title: 'One-Click Publishing',
    description: 'Seamlessly publish your content across multiple platforms.',
    details: [
      'Integrated platform publishing',
      'Scheduled content release',
      'Cross-platform distribution',
      'Automated metadata optimization'
    ]
  }
]

export function Features() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <section className="py-24 bg-gradient-to-b from-white to-blue-50" ref={ref}>
      <div className="container px-4 md:px-6">
        <div className="text-center space-y-4">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl"
          >
            Powerful Features for Professional Podcasting
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed"
          >
            Everything you need to create, optimize, and distribute professional podcasts across all platforms.
          </motion.p>
        </div>

        <div className="grid gap-8 mt-16 md:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="relative z-10 p-8 bg-white rounded-2xl shadow-lg transition-shadow duration-300 hover:shadow-xl">
                <div className="flex items-center space-x-4 mb-6">
                  <div className="p-3 rounded-lg bg-blue-500/10 text-blue-600">
                    <feature.icon className="w-6 h-6" />
                  </div>
                  <h3 className="text-xl font-semibold">{feature.title}</h3>
                </div>
                <p className="text-gray-500 mb-6">{feature.description}</p>
                <Accordion type="single" collapsible>
                  <AccordionItem value="details">
                    <AccordionTrigger className="text-sm text-blue-600 hover:text-blue-700">
                      Learn more
                    </AccordionTrigger>
                    <AccordionContent>
                      <ul className="mt-2 space-y-2 text-sm text-gray-500">
                        {feature.details.map((detail, i) => (
                          <li key={i} className="flex items-center">
                            <div className="mr-2 w-1 h-1 rounded-full bg-blue-500"></div>
                            {detail}
                          </li>
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-purple-500/5 rounded-2xl transform -rotate-1"></div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}