'use client'

import React from 'react'

export function Logo({ className = '' }: { className?: string }) {
  return (
    <div className={`flex items-center ${className}`}>
      <span className="text-3xl font-bold">
        <span className="text-blue-700">Pod</span>
        <span className="bg-gradient-to-r from-purple-700 to-pink-600 bg-clip-text text-transparent">Soul</span>
      </span>
    </div>
  )
}