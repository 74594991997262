import React, { useEffect, useState } from 'react'
import { ArrowLeft, Check, AlertCircle, Zap, Shield, Star } from 'lucide-react'
import { Button } from './ui/button'
import { useNavigate } from 'react-router-dom'
import { plans, checkSubscriptionStatus, type Subscription } from '../services/paypal'
import { Footer } from './Footer'

function Pricing() {
  const navigate = useNavigate()
  const [subscription, setSubscription] = useState<Subscription | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadSubscription = async () => {
      try {
        const sub = await checkSubscriptionStatus()
        setSubscription(sub)
      } catch (error) {
        console.error('Error loading subscription:', error)
      } finally {
        setLoading(false)
      }
    }

    loadSubscription()
  }, [])

  const handleGetStarted = (planId: string) => {
    const plan = plans.find(p => p.id === planId)
    if (!plan) return
    
    window.open(`https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=${plan.paypalPlanId}`, '_blank')
  }

  const handleCancelSubscription = () => {
    window.open('https://youtu.be/z2BS7Cf6Gds?si=ONc0UX5w4SQeI6kQ', '_blank')
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-background flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <div className="absolute inset-0 -z-10 h-full w-full bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)]"></div>
      
      <header className="border-b bg-white/50 backdrop-blur-sm">
        <div className="container flex h-16 items-center">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => navigate('/')}
            className="flex items-center"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Generator
          </Button>
        </div>
      </header>

      <main className="container py-12 flex-1">
        {subscription && (
          <div className="max-w-5xl mx-auto mb-8">
            <div className="rounded-lg bg-white/50 backdrop-blur-sm border shadow-sm p-4">
              <div className="flex items-start gap-3">
                <AlertCircle className="h-5 w-5 text-primary mt-0.5" />
                <div className="flex-1">
                  <h3 className="font-medium">Active Subscription</h3>
                  <p className="text-sm text-muted-foreground">
                    You currently have an active {plans.find(p => p.id === subscription.planId)?.name} plan.
                    {subscription.cancelledAt && (
                      <> Your subscription will end on {subscription.endDate?.toLocaleDateString()}.</>
                    )}
                  </p>
                </div>
                {!subscription.cancelledAt && (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={handleCancelSubscription}
                    className="text-destructive hover:text-destructive"
                  >
                    Cancel Subscription
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="text-center space-y-4 mb-12">
          <h1 className="text-4xl font-bold tracking-tight bg-clip-text text-transparent bg-gradient-to-r from-primary to-purple-600">
            Choose Your Perfect Plan
          </h1>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Select the plan that best fits your podcast creation needs. All plans include high-quality voice generation and AI-powered script writing.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
          {/* Basic Plan */}
          <div className="rounded-2xl border bg-white/50 backdrop-blur-sm p-8 relative overflow-hidden transition-all hover:shadow-lg">
            <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-transparent pointer-events-none"></div>
            
            <div className="relative">
              <div className="flex items-center gap-2 mb-2">
                <Zap className="h-5 w-5 text-blue-500" />
                <h3 className="text-xl font-semibold">Basic</h3>
              </div>
              
              <div className="mt-4 flex items-baseline">
                <span className="text-5xl font-bold tracking-tight">$30</span>
                <span className="ml-1 text-sm text-muted-foreground">/month</span>
              </div>
              
              <ul className="mt-8 space-y-4">
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>30 points included</span>
                </li>
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>High-quality voice generation</span>
                </li>
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>AI-powered script writing</span>
                </li>
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>Basic email support</span>
                </li>
              </ul>
              
              <Button
                className="w-full mt-8"
                size="lg"
                onClick={() => handleGetStarted('basic')}
                disabled={subscription?.status === 'active'}
              >
                {subscription?.status === 'active' ? 'Currently Subscribed' : 'Subscribe with PayPal'}
              </Button>
            </div>
          </div>

          {/* Pro Plan */}
          <div className="rounded-2xl border-2 border-purple-500/20 bg-white/50 backdrop-blur-sm p-8 relative overflow-hidden transition-all hover:shadow-lg scale-105 -mt-4">
            <div className="absolute inset-0 bg-gradient-to-b from-purple-50/50 to-transparent pointer-events-none"></div>
            
            <div className="relative">
              <div className="flex items-center gap-2 mb-2">
                <Star className="h-5 w-5 text-purple-500" />
                <h3 className="text-xl font-semibold">Pro</h3>
              </div>
              
              <div className="mt-4 flex items-baseline">
                <span className="text-5xl font-bold tracking-tight">$80</span>
                <span className="ml-1 text-sm text-muted-foreground">/month</span>
              </div>
              
              <ul className="mt-8 space-y-4">
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>120 points included</span>
                </li>
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>Premium voice quality</span>
                </li>
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>Priority generation queue</span>
                </li>
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>Advanced customization options</span>
                </li>
                <li className="flex items-center">
                  <Check className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>Priority 24/7 support</span>
                </li>
                <li className="flex items-center">
                  <Shield className="h-4 w-4 text-green-500 mr-3 flex-shrink-0" />
                  <span>Enhanced voice consistency</span>
                </li>
              </ul>
              
              <Button
                className="w-full mt-8 bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 shadow-lg"
                size="lg"
                onClick={() => handleGetStarted('pro')}
                disabled={subscription?.status === 'active'}
              >
                {subscription?.status === 'active' ? 'Currently Subscribed' : 'Subscribe with PayPal'}
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-12 text-center">
          <p className="text-sm text-muted-foreground">
            All plans include automatic monthly renewal. Cancel anytime.
          </p>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Pricing