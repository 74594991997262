import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Check } from 'lucide-react'
import { Button } from './ui/button'
import { Link } from 'react-router-dom'

const plans = [
  {
    name: 'Basic',
    price: 30,
    description: 'Perfect for individual content creators',
    features: [
      '30 points included',
      'High-quality voice generation',
      'AI-powered script writing',
      'Basic email support',
      'Platform optimization',
      'Analytics dashboard'
    ]
  },
  {
    name: 'Pro',
    price: 80,
    description: 'For professional podcasters and teams',
    features: [
      '120 points included',
      'Premium voice quality',
      'Priority generation queue',
      'Advanced customization options',
      'Priority 24/7 support',
      'Enhanced voice consistency'
    ]
  }
]

export function Pricing() {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <section className="py-24 bg-white" ref={ref}>
      <div className="container px-4 md:px-6">
        <div className="text-center space-y-4">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl"
          >
            Simple, Transparent Pricing
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed"
          >
            Choose the plan that best fits your podcast creation needs
          </motion.p>
        </div>

        <div className="grid gap-8 mt-16 md:grid-cols-2 lg:max-w-4xl lg:mx-auto">
          {plans.map((plan, index) => (
            <motion.div
              key={plan.name}
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative group"
            >
              <div className="relative z-10 p-8 bg-white rounded-2xl shadow-lg transition-shadow duration-300 hover:shadow-xl">
                <h3 className="text-2xl font-bold">{plan.name}</h3>
                <div className="mt-4 flex items-baseline">
                  <span className="text-4xl font-bold">${plan.price}</span>
                  <span className="ml-1 text-gray-500">/month</span>
                </div>
                <p className="mt-4 text-gray-500">{plan.description}</p>
                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-center">
                      <Check className="h-5 w-5 text-green-500 mr-3" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="mt-8 space-y-4">
                  <Link to="/auth" className="block w-full">
                    <Button
                      className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:from-blue-700 hover:to-purple-700 shadow-lg hover:shadow-xl transition-all duration-200"
                      size="lg"
                    >
                      Get Started
                    </Button>
                  </Link>
                  <Link to="/auth?demo=true" className="block w-full">
                    <Button
                      variant="outline"
                      className="w-full border-2 border-blue-600/20 hover:border-blue-600/30 hover:bg-blue-50/50 text-blue-700"
                      size="lg"
                    >
                      Schedule Free Demo
                    </Button>
                  </Link>
                </div>
              </div>
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/5 to-purple-500/5 rounded-2xl transform -rotate-1"></div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}