import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

export interface Platform {
  value: string
  label: string
  duration: string
  description: string
  cost?: number
}

export const platforms: Platform[] = [
  {
    value: "youtube",
    label: "YouTube",
    duration: ">8",
    description: "Long-form content perfect for in-depth discussions",
    cost: 5
  },
  {
    value: "tiktok",
    label: "TikTok",
    duration: ">1",
    description: "Short-form content optimized for engagement",
    cost: 1
  },
  {
    value: "youtube-shorts",
    label: "YouTube Shorts",
    duration: "<1",
    description: "Bite-sized vertical video format",
    cost: 1
  },
  {
    value: "instagram-reels",
    label: "Instagram Reels",
    duration: "<1",
    description: "Short vertical videos for Instagram",
    cost: 1
  },
  {
    value: "podcast",
    label: "Podcast Platforms",
    duration: ">15",
    description: "Traditional podcast format for Spotify, Apple Podcasts, etc.",
    cost: 5
  }
]

export function Combobox({
  value,
  onChange
}: {
  value: string
  onChange: (value: string) => void
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between bg-white/80"
        >
          {value
            ? platforms.find((platform) => platform.value === value)?.label
            : "Select platform..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder="Search platform..." />
          <CommandEmpty>No platform found.</CommandEmpty>
          <CommandGroup>
            {platforms.map((platform) => (
              <CommandItem
                key={platform.value}
                value={platform.value}
                onSelect={(currentValue) => {
                  onChange(currentValue === value ? "" : currentValue)
                  setOpen(false)
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === platform.value ? "opacity-100" : "opacity-0"
                  )}
                />
                <div className="flex flex-col">
                  <div className="flex items-center">
                    <span>{platform.label}</span>
                    {platform.cost && platform.cost > 1 && (
                      <span className="ml-2 text-xs px-1.5 py-0.5 bg-amber-100 text-amber-800 rounded-full">
                        {platform.cost} points
                      </span>
                    )}
                  </div>
                  <span className="text-xs text-muted-foreground">
                    {platform.duration} minutes • {platform.description}
                  </span>
                </div>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}