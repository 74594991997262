import React from 'react'
import { Link } from 'react-router-dom'

export function Footer() {
  return (
    <footer className="border-t bg-white/50 backdrop-blur-sm py-4">
      <div className="container">
        <div className="flex justify-center items-center space-x-4 text-sm text-muted-foreground">
          <Link 
            to="/terms" 
            className="hover:text-foreground transition-colors"
          >
            Terms and Conditions
          </Link>
          <span>|</span>
          <Link 
            to="/privacy" 
            className="hover:text-foreground transition-colors"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  )
}