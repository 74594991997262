import React, { useState, useEffect } from 'react'
import { Loader2, Volume2, LogOut, CreditCard, Sparkles, Bot, AlertCircle, Clock } from 'lucide-react'
import { generatePodcastScript, type PodcastScript } from '../services/deepseek'
import { generatePodcastAudio } from '../services/elevenlabs'
import { useAuthStore } from '../store/auth'
import { usePointsStore } from '../store/points'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { Label } from './ui/label'
import { useNavigate } from 'react-router-dom'
import { Combobox, platforms } from './ui/combobox'
import { Footer } from './Footer'
import { Logo } from './Logo'

export function PodcastGenerator() {
  const [topic, setTopic] = useState('')
  const [loading, setLoading] = useState(false)
  const [script, setScript] = useState<PodcastScript | null>(null)
  const [audioUrl, setAudioUrl] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [platform, setPlatform] = useState<string>('')
  const [generationProgress, setGenerationProgress] = useState<string | null>(null)
  const [generationStep, setGenerationStep] = useState<number>(0)
  const [totalSteps, setTotalSteps] = useState<number>(0)
  const { signOut, user } = useAuthStore()
  const { points, fetchPoints, incrementPoints } = usePointsStore()
  const navigate = useNavigate()

  useEffect(() => {
    const loadPoints = async () => {
      try {
        if (!user) {
          navigate('/auth')
          return
        }
        await fetchPoints()
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Unable to load points. Please try again.'
        if (errorMessage === 'Please sign in to continue') {
          navigate('/auth')
        } else {
          console.error('Error fetching points:', errorMessage)
          setError(errorMessage)
        }
      }
    }
    loadPoints()
  }, [fetchPoints, navigate, user])

  const getPlatformCost = (platformValue: string): number => {
    const selectedPlatform = platforms.find(p => p.value === platformValue)
    return selectedPlatform?.cost || 1
  }

  const getPlatformDuration = (platformValue: string): string => {
    const selectedPlatform = platforms.find(p => p.value === platformValue)
    return selectedPlatform?.duration || '>8'
  }

  const handleGenerate = async () => {
    if (!topic.trim()) {
      setError('Please enter a topic')
      return
    }

    if (!platform) {
      setError('Please select a platform')
      return
    }

    const pointCost = getPlatformCost(platform)
    
    if (points < pointCost) {
      setError(`You need at least ${pointCost} points to generate a podcast for this platform`)
      return
    }

    setLoading(true)
    setError(null)
    setScript(null)
    setAudioUrl(null)
    
    // Set total steps based on platform
    const duration = getPlatformDuration(platform);
    const isLongFormat = duration === '>8' || duration === '>15';
    const isVeryShortFormat = duration === '<1';
    const steps = isLongFormat ? 5 : (isVeryShortFormat ? 2 : 3);
    setTotalSteps(steps);
    setGenerationStep(1);
    setGenerationProgress('Generating script...');

    let pointDeducted = false;
    try {
      await incrementPoints(-pointCost)
      pointDeducted = true

      // Step 1: Generate script
      setGenerationProgress(isVeryShortFormat ? 'Creating short-form content...' : 'Creating podcast script...');
      const scriptContent = await generatePodcastScript(topic, duration)
      setScript(scriptContent)
      
      if (isVeryShortFormat) {
        setGenerationStep(2); // Skip to final step for very short formats
        setGenerationProgress('Generating audio...');
      } else {
        setGenerationStep(isLongFormat ? 2 : 2);
      }
      
      // Step 2-4 for long formats: Generate audio in stages
      if (isLongFormat) {
        setGenerationProgress('Processing voice for first speaker...');
        setGenerationStep(3);
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        setGenerationProgress('Processing voice for second speaker...');
        setGenerationStep(4);
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
      
      // Final step: Generate audio
      setGenerationProgress(isVeryShortFormat ? 'Finalizing short-form audio...' : 'Finalizing audio production...');
      setGenerationStep(steps);
      
      const audioBlob = await generatePodcastAudio(
        scriptContent.speaker1Lines,
        scriptContent.speaker2Lines,
        duration
      )
      const url = URL.createObjectURL(audioBlob)
      setAudioUrl(url)
      setGenerationProgress(null)
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unexpected error occurred. Please try again.'
      console.error('Generation error:', errorMessage)
      setError(errorMessage)
      
      if (pointDeducted) {
        try {
          await incrementPoints(pointCost)
        } catch (refundErr) {
          const refundError = refundErr instanceof Error ? refundErr.message : 'Unknown error'
          console.error('Error refunding points:', refundError)
          setError((prevError) => 
            `${prevError}. Additionally, we couldn't refund your points - please contact support.`
          )
        }
      }
    } finally {
      setLoading(false)
      setGenerationProgress(null)
      setGenerationStep(0)
      try {
        await fetchPoints()
      } catch (err) {
        const refreshError = err instanceof Error ? err.message : 'Unknown error'
        console.error('Error refreshing points:', refreshError)
      }
    }
  }

  const getButtonText = () => {
    const cost = getPlatformCost(platform)
    return `Generate Podcast (${cost} point${cost !== 1 ? 's' : ''})`
  }

  const hasEnoughPoints = () => {
    if (!platform) return false
    const cost = getPlatformCost(platform)
    return points >= cost
  }

  const getEstimatedDuration = () => {
    if (!platform) return null
    
    const duration = getPlatformDuration(platform)
    if (duration === '<1') return 'approximately 30-45 seconds'
    if (duration === '>1') return 'approximately 1-2 minutes'
    if (duration === '>8') return 'approximately 8-10 minutes'
    if (duration === '>15') return 'approximately 15-20 minutes'
    return null
  }

  const getPlatformDescription = () => {
    if (!platform) return null
    
    const selectedPlatform = platforms.find(p => p.value === platform)
    if (!selectedPlatform) return null
    
    switch (platform) {
      case 'youtube-shorts':
        return 'Perfect for YouTube Shorts - vertical, bite-sized content under 60 seconds'
      case 'instagram-reels':
        return 'Optimized for Instagram Reels - short, engaging vertical videos'
      case 'tiktok':
        return 'Ideal for TikTok - short-form content optimized for engagement'
      case 'youtube':
        return 'Perfect for YouTube - longer, in-depth content for detailed discussions'
      case 'podcast':
        return 'Traditional podcast format for Spotify, Apple Podcasts, and other platforms'
      default:
        return selectedPlatform.description
    }
  }

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <div className="absolute inset-0 -z-10 h-full w-full bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)]"></div>
      
      <header className="border-b bg-white/50 backdrop-blur-sm sticky top-0 z-10">
        <div className="container flex h-16 items-center justify-between">
          <Logo />
          <div className="flex items-center space-x-4">
            <div className="text-sm font-medium bg-white/80 backdrop-blur-sm px-4 py-1.5 rounded-full border">
              {points} point{points !== 1 ? 's' : ''}
            </div>
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                size="sm"
                onClick={() => navigate('/pricing')}
                className="h-9 bg-white/50 backdrop-blur-sm"
              >
                <CreditCard className="h-4 w-4 mr-2" />
                Plans
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => signOut()}
                className="h-9 bg-white/50 backdrop-blur-sm"
              >
                <LogOut className="h-4 w-4 mr-2" />
                Sign out
              </Button>
            </div>
          </div>
        </div>
      </header>

      <main className="container py-8 flex-1">
        <div className="mx-auto max-w-3xl">
          <div className="rounded-2xl border bg-white/50 backdrop-blur-sm p-8 shadow-sm">
            <div className="space-y-6">
              <div className="space-y-2">
                <Label htmlFor="platform" className="text-lg font-medium">
                  Choose your platform
                </Label>
                <Combobox value={platform} onChange={setPlatform} />
                <p className="text-sm text-muted-foreground">
                  Select the platform where you'll share your podcast to optimize the content length.
                  {platform && getPlatformCost(platform) > 1 ? (
                    <span className="text-amber-600 font-medium"> Note: This platform costs {getPlatformCost(platform)} points.</span>
                  ) : null}
                </p>
                {platform && (
                  <div className="mt-2 space-y-1.5">
                    <div className="flex items-center text-sm text-blue-600">
                      <Clock className="h-3.5 w-3.5 mr-1.5" />
                      <p>Your podcast will be {getEstimatedDuration()} long based on the selected platform.</p>
                    </div>
                    <p className="text-xs text-muted-foreground">{getPlatformDescription()}</p>
                  </div>
                )}
              </div>

              <div className="space-y-2">
                <Label htmlFor="topic" className="text-lg font-medium">
                  What would you like your podcast to be about?
                </Label>
                <div className="relative">
                  <Input
                    id="topic"
                    placeholder="e.g., AI in healthcare, Space exploration, Climate change..."
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    className="pr-10 bg-white/80"
                  />
                  <Bot className="absolute right-3 top-2.5 h-4 w-4 text-muted-foreground" />
                </div>
              </div>

              {error && (
                <div className="p-4 rounded-lg bg-destructive/10 text-destructive flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 flex-shrink-0 mt-0.5" />
                  <p className="text-sm">{error}</p>
                </div>
              )}

              {loading && generationStep > 0 && (
                <div className="p-4 rounded-lg bg-blue-50 text-blue-800">
                  <div className="space-y-2">
                    <div className="flex items-center">
                      <Loader2 className="h-4 w-4 animate-spin mr-2" />
                      <p className="font-medium">{generationProgress}</p>
                    </div>
                    <div className="w-full bg-blue-200 rounded-full h-2.5">
                      <div 
                        className="bg-blue-600 h-2.5 rounded-full transition-all duration-300" 
                        style={{ width: `${(generationStep / totalSteps) * 100}%` }}
                      ></div>
                    </div>
                    <p className="text-xs text-blue-600">Step {generationStep} of {totalSteps}</p>
                  </div>
                </div>
              )}

              <Button
                onClick={handleGenerate}
                disabled={loading || !topic.trim() || !platform || !hasEnoughPoints()}
                className="w-full bg-gradient-to-r from-primary to-purple-600 hover:from-primary/90 hover:to-purple-600/90"
                size="lg"
              >
                {loading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Generating...
                  </>
                ) : (
                  <>
                    <Sparkles className="mr-2 h-4 w-4" />
                    {getButtonText()}
                  </>
                )}
              </Button>

              {platform && !hasEnoughPoints() && !error && (
                <div className="p-4 rounded-lg bg-yellow-100/50 backdrop-blur-sm text-yellow-800 flex items-start gap-2">
                  <AlertCircle className="h-5 w-5 flex-shrink-0 mt-0.5" />
                  <div className="space-y-1">
                    <p className="font-medium">Insufficient Points</p>
                    <p className="text-sm">
                      You need {getPlatformCost(platform)} points to generate a podcast for this platform. 
                      Visit our pricing page to get more points.
                    </p>
                  </div>
                </div>
              )}

              {script && (
                <div className="space-y-6 mt-8">
                  <div>
                    <h2 className="text-xl font-semibold mb-4 flex items-center">
                      <Bot className="mr-2 h-5 w-5 text-primary" />
                      Generated Script
                    </h2>
                    <div className="rounded-lg border bg-white/80 backdrop-blur-sm p-6 space-y-4 max-h-[500px] overflow-y-auto">
                      {script.speaker1Lines.map((line, index) => (
                        <React.Fragment key={index}>
                          <div className="flex items-start space-x-3">
                            <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-primary/10 text-primary text-sm font-medium">1</span>
                            <p className="text-card-foreground">{line}</p>
                          </div>
                          {script.speaker2Lines[index] && (
                            <div className="flex items-start space-x-3">
                              <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-purple-100 text-purple-600 text-sm font-medium">2</span>
                              <p className="text-card-foreground">{script.speaker2Lines[index]}</p>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>

                  {audioUrl && (
                    <div>
                      <h2 className="text-xl font-semibold mb-4 flex items-center">
                        <Volume2 className="mr-2 h-5 w-5 text-primary" />
                        Generated Audio
                      </h2>
                      <div className="rounded-lg border bg-white/80 backdrop-blur-sm p-4">
                        <audio controls className="w-full">
                          <source src={audioUrl} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>

      <div className="container flex justify-center py-4">
        <a 
          href="https://www.producthunt.com/posts/podsoul?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-podsoul" 
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-90 transition-opacity"
        >
          <img 
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=936065&theme=light&t=1741122354152" 
            alt="PodSoul - AI faceless content creators | Product Hunt" 
            style={{ width: '250px', height: '54px' }} 
            width="250" 
            height="54" 
          />
        </a>
      </div>

      <Footer />
    </div>
  )
}