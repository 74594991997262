import React from 'react'
import { Hero } from '../app/components/hero'
import { Features } from '../app/components/features'
import { SocialProof } from '../app/components/social-proof'
import { Pricing } from '../app/components/pricing'
import { ConversionForm } from '../app/components/conversion-form'
import { Link } from 'react-router-dom'

export function LandingPage() {
  return (
    <>
      {/* Breadcrumbs */}
      <nav aria-label="Breadcrumb" className="container py-4">
        <ol className="flex items-center space-x-2 text-sm text-gray-500">
          <li>
            <Link to="/" className="hover:text-primary">Home</Link>
          </li>
        </ol>
      </nav>

      <main className="min-h-screen">
        <Hero />
        
        {/* FAQ Section for SEO */}
        <section className="py-24 bg-white" id="faq">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tight text-center mb-12">
              Frequently Asked Questions about AI Podcast Creation
            </h2>
            <div className="grid gap-8 md:grid-cols-2 max-w-4xl mx-auto">
              <div className="space-y-4">
                <h3 className="text-xl font-semibold">What is AI podcast generation?</h3>
                <p className="text-gray-600">AI podcast generation uses artificial intelligence to create professional-quality podcast content, including script writing and voice synthesis, automating the entire production process.</p>
              </div>
              <div className="space-y-4">
                <h3 className="text-xl font-semibold">How does automated podcast production work?</h3>
                <p className="text-gray-600">Our AI technology analyzes your topic, generates engaging scripts, and uses advanced voice synthesis to create natural-sounding conversations, all while maintaining professional quality.</p>
              </div>
              <div className="space-y-4">
                <h3 className="text-xl font-semibold">What are the benefits of AI podcasting?</h3>
                <p className="text-gray-600">AI podcasting saves time, reduces production costs, ensures consistent quality, and allows for rapid content creation while maintaining professional standards.</p>
              </div>
              <div className="space-y-4">
                <h3 className="text-xl font-semibold">Can AI create natural-sounding podcasts?</h3>
                <p className="text-gray-600">Yes, our advanced AI uses state-of-the-art voice synthesis technology to create natural-sounding conversations with proper intonation and emotional expression.</p>
              </div>
            </div>
          </div>
        </section>

        <Features />
        <SocialProof />
        <Pricing />
        <ConversionForm />
        
        {/* Product Hunt Section */}
        <section className="py-16 bg-gradient-to-b from-white to-blue-50">
          <div className="container">
            <div className="text-center space-y-6">
              <h2 className="text-3xl font-bold tracking-tight">
                Featured on Product Hunt
              </h2>
              <p className="text-gray-500 max-w-2xl mx-auto">
                Join thousands of content creators who are already using PodSoul to transform their ideas into professional podcasts.
              </p>
              <div className="flex justify-center mt-8">
                <a 
                  href="https://www.producthunt.com/posts/podsoul?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-podsoul" 
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-90 transition-opacity transform hover:scale-105 duration-200"
                >
                  <img 
                    src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=936065&theme=light&t=1741122354152" 
                    alt="PodSoul - AI faceless content creators | Product Hunt" 
                    style={{ width: '250px', height: '54px' }} 
                    width="250" 
                    height="54" 
                  />
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Rich Content for SEO */}
        <section className="py-16 bg-white" aria-hidden="true">
          <div className="container prose prose-blue mx-auto px-4">
            <h2 className="sr-only">About AI Podcast Generation</h2>
            <div className="text-gray-600">
              <p>
                Transform your content creation with artificial intelligence podcast generation. 
                Our AI-powered platform enables creators to produce professional-quality podcasts 
                efficiently and consistently. Using advanced natural language processing and voice 
                synthesis technology, PodSoul automates the entire podcast production workflow.
              </p>
              <p>
                Whether you're creating content for YouTube, Spotify, Apple Podcasts, or other 
                platforms, our automated podcast production system ensures high-quality output 
                every time. The AI podcast creator handles everything from script generation to 
                voice synthesis, making professional podcast creation accessible to everyone.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}