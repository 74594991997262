import React from 'react'
import { ArrowLeft } from 'lucide-react'
import { Button } from './ui/button'
import { useNavigate } from 'react-router-dom'
import { Footer } from './Footer'

function Privacy() {
  const navigate = useNavigate()
  const effectiveDate = '2/24/2025'

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <div className="absolute inset-0 -z-10 h-full w-full bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)]"></div>
      
      <header className="border-b bg-white/50 backdrop-blur-sm">
        <div className="container flex h-16 items-center">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => navigate('/')}
            className="flex items-center"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Generator
          </Button>
        </div>
      </header>

      <main className="container py-12 flex-1">
        <div className="max-w-4xl mx-auto">
          <div className="prose prose-slate mx-auto">
            <h1 className="text-4xl font-bold tracking-tight text-center mb-8">Privacy Policy</h1>
            <p className="text-sm text-muted-foreground text-center mb-12">Effective Date: {effectiveDate}</p>

            <div className="space-y-8">
              <section>
                <p className="text-muted-foreground">
                  Welcome to Podsoul.com! Your privacy is important to us. This Privacy Policy outlines how we collect, 
                  use, disclose, and safeguard your information when you visit our website, use our services, or 
                  interact with us. By accessing or using our services, you agree to the terms of this Privacy Policy.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
                <p>We may collect the following types of information:</p>
                <ul className="list-disc pl-6 mt-2 space-y-2">
                  <li>
                    <strong>Personal Information:</strong> We collect your email address when you sign up for our 
                    services, contact us for customer support, or subscribe to our newsletter.
                  </li>
                  <li>
                    <strong>Usage Data:</strong> We may collect information about how you access and use our website, 
                    including your IP address, browser type, and pages visited.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">2. Purpose of Data Processing</h2>
                <p>We process your information for the following purposes:</p>
                <ul className="list-disc pl-6 mt-2 space-y-2">
                  <li>
                    <strong>To Provide Our Services:</strong> We use your email address to create and manage your 
                    account, provide customer support, and communicate with you regarding your inquiries and our services.
                  </li>
                  <li>
                    <strong>To Improve Our Services:</strong> We analyze usage data to enhance our website and 
                    services, ensuring a better user experience.
                  </li>
                  <li>
                    <strong>To Send Communications:</strong> We may send you updates, newsletters, and promotional 
                    materials related to our services. You can opt out of these communications at any time.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">3. Legal Basis for Processing Personal Information</h2>
                <p>We process your personal information based on the following legal grounds:</p>
                <ul className="list-disc pl-6 mt-2 space-y-2">
                  <li>
                    <strong>Consent:</strong> When you provide your email address for specific purposes, you consent 
                    to our processing of your information for those purposes.
                  </li>
                  <li>
                    <strong>Legitimate Interests:</strong> We may process your data to pursue our legitimate interests, 
                    such as improving our services and providing customer support.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">4. Data Retention</h2>
                <p>
                  We will retain your personal information only for as long as necessary to fulfill the purposes 
                  outlined in this Privacy Policy or as required by law. When your information is no longer needed, 
                  we will securely delete or anonymize it.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">5. Data Sharing and Disclosure</h2>
                <p>
                  We do not sell, trade, or otherwise transfer your personal information to third parties without 
                  your consent, except in the following circumstances:
                </p>
                <ul className="list-disc pl-6 mt-2 space-y-2">
                  <li>
                    <strong>Service Providers:</strong> We may share your information with third-party service 
                    providers who assist us in operating our website, conducting our business, or servicing you, 
                    provided these parties agree to keep your information confidential.
                  </li>
                  <li>
                    <strong>Legal Compliance:</strong> We may disclose your information if required by law or in 
                    response to valid requests by public authorities.
                  </li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">6. International Data Transfers</h2>
                <p>
                  Your information may be transferred to and processed in countries outside of your jurisdiction. 
                  We will take appropriate measures to ensure that your personal information receives an adequate 
                  level of protection in accordance with applicable data protection laws.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">7. User Rights</h2>
                <p>You have the following rights regarding your personal information:</p>
                <ul className="list-disc pl-6 mt-2 space-y-2">
                  <li>
                    <strong>Access:</strong> You have the right to request copies of your personal information.
                  </li>
                  <li>
                    <strong>Rectification:</strong> You have the right to request that we correct any information 
                    you believe is inaccurate or incomplete.
                  </li>
                  <li>
                    <strong>Erasure:</strong> You have the right to request the deletion of your personal 
                    information under certain conditions.
                  </li>
                  <li>
                    <strong>Restriction of Processing:</strong> You have the right to request that we restrict the 
                    processing of your personal information under certain conditions.
                  </li>
                  <li>
                    <strong>Data Portability:</strong> You have the right to request that we transfer the data 
                    that we have collected to another organization, or directly to you, under certain conditions.
                  </li>
                </ul>
                <p className="mt-4">
                  To exercise any of these rights, please contact us using the contact information provided below.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">8. Security of Your Information</h2>
                <p>
                  We take the security of your personal information seriously and implement reasonable technical 
                  and organizational measures to protect it from unauthorized access, use, or disclosure. However, 
                  no method of transmission over the internet or method of electronic storage is 100% secure. 
                  While we strive to protect your personal information, we cannot guarantee its absolute security.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">9. Changes to This Privacy Policy</h2>
                <p>
                  We may update our Privacy Policy from time to time. We will notify you of any changes by posting 
                  the new Privacy Policy on our website and updating the effective date at the top of this policy. 
                  We encourage you to review this Privacy Policy periodically for any changes.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                <div className="mt-2">
                  <p>Podsoul.com</p>
                  <p>Email: podsoul.com@gmail.com</p>
                  <p>Website: podsoul.com</p>
                </div>
              </section>

              <hr className="my-8" />

              <p className="text-center text-sm text-muted-foreground">
                Thank you for trusting Podsoul.com with your information. We are committed to protecting your 
                privacy and ensuring a safe and enjoyable experience with our services.
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Privacy