import { supabase } from '../lib/supabase'

export interface Plan {
  id: string
  name: string
  price: number
  points: number
  paypalPlanId: string
}

export const plans: Plan[] = [
  {
    id: 'basic',
    name: 'Basic',
    price: 30,
    points: 30,
    paypalPlanId: 'P-01T79889CG779373NM7AJ6AI'
  },
  {
    id: 'pro',
    name: 'Pro',
    price: 80,
    points: 120,
    paypalPlanId: 'P-6D562789J5061810PM62OMUI'
  },
]

export interface Subscription {
  id: string
  userId: string
  planId: string
  paypalSubscriptionId: string
  status: 'active' | 'cancelled' | 'expired'
  startDate: Date
  endDate: Date | null
  cancelledAt: Date | null
}

export async function checkSubscriptionStatus(): Promise<Subscription | null> {
  try {
    const { data: { user } } = await supabase.auth.getUser()
    if (!user) return null

    const { data, error } = await supabase
      .from('subscriptions')
      .select('*')
      .eq('user_id', user.id)
      .eq('status', 'active')
      .maybeSingle()

    if (error && error.code !== 'PGRST116') {
      console.error('Error checking subscription:', error)
      return null
    }

    return data ? {
      id: data.id,
      userId: data.user_id,
      planId: data.plan_id,
      paypalSubscriptionId: data.paypal_subscription_id,
      status: data.status,
      startDate: new Date(data.start_date),
      endDate: data.end_date ? new Date(data.end_date) : null,
      cancelledAt: data.cancelled_at ? new Date(data.cancelled_at) : null
    } : null
  } catch (error) {
    console.error('Error checking subscription:', error)
    return null
  }
}

export async function handlePayPalWebhook(event: any): Promise<void> {
  const { data: { user } } = await supabase.auth.getUser()
  if (!user) throw new Error('User not authenticated')

  // Handle different PayPal webhook events
  switch (event.event_type) {
    case 'BILLING.SUBSCRIPTION.CREATED':
      await supabase.from('subscriptions').insert({
        user_id: user.id,
        plan_id: event.resource.plan_id,
        paypal_subscription_id: event.resource.id,
        status: 'active',
        start_date: new Date().toISOString(),
      })
      break

    case 'BILLING.SUBSCRIPTION.CANCELLED':
      await supabase.from('subscriptions')
        .update({
          status: 'cancelled',
          cancelled_at: new Date().toISOString(),
          end_date: event.resource.end_time
        })
        .eq('paypal_subscription_id', event.resource.id)
      break

    case 'BILLING.SUBSCRIPTION.EXPIRED':
      await supabase.from('subscriptions')
        .update({
          status: 'expired',
          end_date: event.resource.end_time
        })
        .eq('paypal_subscription_id', event.resource.id)
      break
  }
}

export async function addPointsToUser(points: number): Promise<void> {
  const { data: { user } } = await supabase.auth.getUser()
  if (!user) throw new Error('User not authenticated')

  const { error } = await supabase.rpc('add_points', { point_amount: points })
  if (error) throw error
}