import { create } from 'zustand'
import { supabase } from '../lib/supabase'
import { User } from '@supabase/supabase-js'

interface AuthState {
  user: User | null
  loading: boolean
  signIn: (email: string, password: string) => Promise<void>
  signUp: (email: string, password: string) => Promise<void>
  signOut: () => Promise<void>
  initialize: () => void
}

const MAX_RETRIES = 3
const RETRY_DELAY = 1000 // 1 second

async function retry<T>(
  fn: () => Promise<T>,
  retries = MAX_RETRIES,
  delay = RETRY_DELAY
): Promise<T> {
  try {
    return await fn()
  } catch (error) {
    if (retries > 0) {
      await new Promise(resolve => setTimeout(resolve, delay))
      return retry(fn, retries - 1, delay * 2)
    }
    throw error
  }
}

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  loading: true,
  signIn: async (email: string, password: string) => {
    try {
      const { error, data } = await retry(() =>
        supabase.auth.signInWithPassword({
          email,
          password,
        })
      )
      
      if (error) throw error

      if (data.user) {
        const { error: upsertError } = await supabase
          .from('users')
          .upsert({
            id: data.user.id,
            email: data.user.email,
          })
          .select()

        if (upsertError) throw upsertError
        set({ user: data.user })
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Authentication failed: ${error.message}`)
      }
      throw new Error('Authentication failed. Please try again.')
    }
  },
  signUp: async (email: string, password: string) => {
    try {
      const { error, data } = await retry(() =>
        supabase.auth.signUp({
          email,
          password,
        })
      )
      
      if (error) throw error

      if (data.user) {
        const { error: insertError } = await supabase
          .from('users')
          .insert({
            id: data.user.id,
            email: data.user.email,
          })
          .select()

        if (insertError) throw insertError
        set({ user: data.user })
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Sign up failed: ${error.message}`)
      }
      throw new Error('Sign up failed. Please try again.')
    }
  },
  signOut: async () => {
    try {
      const { error } = await retry(() => supabase.auth.signOut())
      if (error) throw error
      set({ user: null })
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(`Sign out failed: ${error.message}`)
      }
      throw new Error('Sign out failed. Please try again.')
    }
  },
  initialize: () => {
    const initializeAuth = async () => {
      try {
        const { data: { session } } = await retry(() => supabase.auth.getSession())
        set({ user: session?.user ?? null, loading: false })

        supabase.auth.onAuthStateChange((_event, session) => {
          set({ user: session?.user ?? null })
          
          if (session?.user) {
            supabase
              .from('users')
              .upsert({
                id: session.user.id,
                email: session.user.email,
              })
              .select()
              .then(({ error }) => {
                if (error) console.error('Error upserting user:', error)
              })
          }
        })
      } catch (error) {
        console.error('Error initializing auth:', error)
        set({ loading: false })
        if (error instanceof Error) {
          throw new Error(`Authentication initialization failed: ${error.message}`)
        }
        throw new Error('Authentication initialization failed. Please refresh the page.')
      }
    }

    initializeAuth()
  },
}))