import { create } from 'zustand'
import { supabase } from '../lib/supabase'

interface PointsState {
  points: number
  lastInteraction: Date | null
  incrementPoints: (amount: number) => Promise<void>
  fetchPoints: () => Promise<void>
}

export const usePointsStore = create<PointsState>((set) => ({
  points: 0,
  lastInteraction: null,
  incrementPoints: async (amount: number) => {
    try {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) {
        throw new Error('Please sign in to continue')
      }

      const { data: userData, error: fetchError } = await supabase
        .from('users')
        .select('points')
        .eq('id', user.id)
        .single()

      if (fetchError) {
        if (fetchError.code === 'PGRST116') {
          const { data: newUser, error: insertError } = await supabase
            .from('users')
            .insert({
              id: user.id,
              email: user.email,
              points: Math.max(0, amount)
            })
            .select()
            .single()

          if (insertError) {
            console.error('Error creating user record:', insertError)
            throw new Error('Unable to initialize points. Please try again.')
          }

          if (newUser) {
            set({ 
              points: newUser.points,
              lastInteraction: new Date()
            })
          }
          return
        }
        console.error('Error fetching user points:', fetchError)
        throw new Error('Unable to access points. Please try again.')
      }

      const currentPoints = userData?.points || 0
      const newPoints = Math.max(0, currentPoints + amount)

      const { data, error: updateError } = await supabase
        .from('users')
        .update({ 
          points: newPoints,
          last_interaction: new Date().toISOString()
        })
        .eq('id', user.id)
        .select()
        .single()

      if (updateError) {
        console.error('Error updating points:', updateError)
        throw new Error('Unable to update points. Please try again.')
      }

      if (data) {
        set({ 
          points: data.points,
          lastInteraction: new Date(data.last_interaction)
        })
      }
    } catch (error) {
      console.error('Error incrementing points:', error)
      throw error instanceof Error 
        ? error 
        : new Error('Unable to update points. Please try again.')
    }
  },
  fetchPoints: async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser()
      if (!user) {
        throw new Error('Please sign in to continue')
      }

      const { data, error } = await supabase
        .from('users')
        .select('points, last_interaction')
        .eq('id', user.id)
        .single()

      if (error) {
        if (error.code === 'PGRST116') {
          const { data: newUser, error: insertError } = await supabase
            .from('users')
            .insert({
              id: user.id,
              email: user.email,
              points: 0
            })
            .select()
            .single()

          if (insertError) {
            console.error('Error creating user record:', insertError)
            throw new Error('Unable to initialize points. Please try again.')
          }

          if (newUser) {
            set({
              points: newUser.points,
              lastInteraction: newUser.last_interaction ? new Date(newUser.last_interaction) : null
            })
          }
          return
        }
        console.error('Error fetching points:', error)
        throw new Error('Unable to access points. Please try again.')
      }
      
      if (data) {
        set({ 
          points: data.points,
          lastInteraction: data.last_interaction ? new Date(data.last_interaction) : null
        })
      }
    } catch (error) {
      console.error('Error fetching points:', error)
      throw error instanceof Error 
        ? error 
        : new Error('Unable to load points. Please try again.')
    }
  }
}))