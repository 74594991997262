import OpenAI from 'openai';

// Development-only API key
const DEEPSEEK_API_KEY = 'sk-fb28a6ec9928428691cc161f31fe4bcb';

const openai = new OpenAI({
  apiKey: DEEPSEEK_API_KEY,
  baseURL: 'https://api.deepseek.com/v1',
  dangerouslyAllowBrowser: true,
});

export interface PodcastScript {
  speaker1Lines: string[];
  speaker2Lines: string[];
}

export async function generatePodcastScript(topic: string, duration: string = '>8'): Promise<PodcastScript> {
  if (!topic.trim()) {
    throw new Error('Topic cannot be empty');
  }

  // Determine the target length based on duration
  let targetLength = '';
  let lineCount = 0;
  let wordCount = 0;
  let minWordsPerLine = 0;
  let maxWordsPerLine = 0;
  
  if (duration === '<1') {
    targetLength = 'very short (30-45 seconds)';
    lineCount = 4; // 2 exchanges
    wordCount = 120; // ~30-45 seconds of speech
    minWordsPerLine = 15;
    maxWordsPerLine = 40; // Limit length for short formats
  } else if (duration === '>1') {
    targetLength = 'short (1-2 minutes)';
    lineCount = 6; // 3 exchanges
    wordCount = 250; // ~1-2 minutes of speech
    minWordsPerLine = 20;
    maxWordsPerLine = 60; // Limit length for short formats
  } else if (duration === '>8') {
    targetLength = 'long (8-10 minutes)';
    lineCount = 30; // 15 exchanges
    wordCount = 2500; // ~8-10 minutes of speech
    minWordsPerLine = 50;
    maxWordsPerLine = 150;
  } else if (duration === '>15') {
    targetLength = 'very long (15-20 minutes)';
    lineCount = 40; // 20 exchanges
    wordCount = 4000; // ~15-20 minutes of speech
    minWordsPerLine = 60;
    maxWordsPerLine = 200;
  }

  const prompt = `Create an engaging debate-style podcast conversation between two hosts about "${topic}". 

Key requirements:
- Host 1 and Host 2 should have contrasting viewpoints or approaches to the topic
- Include respectful disagreements and counterarguments
- Back opinions with reasoning or examples
- Maintain a professional yet passionate tone
- Keep the debate balanced, giving both sides fair representation
- End with some points of agreement or mutual understanding
- Make the podcast ${targetLength} in length
- CRITICAL: The conversation MUST have exactly ${lineCount} total lines between both speakers
- CRITICAL: The total word count MUST be approximately ${wordCount} words
- CRITICAL: Each line should have between ${minWordsPerLine} and ${maxWordsPerLine} words
${duration === '<1' || duration === '>1' ? '- CRITICAL: For this short format, keep each exchange brief, direct, and impactful' : ''}
${duration === '>8' || duration === '>15' ? '- For longer podcasts, ensure each speaker has substantial dialogue with detailed explanations, examples, and stories' : ''}

The conversation should follow this structure:
${duration === '<1' ? 
  '1. Quick introduction of the topic (1 line)\n2. Main contrasting viewpoints (2 lines)\n3. Brief conclusion (1 line)' : 
  duration === '>1' ? 
  '1. Brief introduction of the topic (2 lines)\n2. Main contrasting viewpoints (2 lines)\n3. Quick conclusion with common ground (2 lines)' :
  '1. Introduction of the topic and initial positions (about 20% of the content)\n2. Main debate points with back-and-forth discussion (about 60% of the content)\n3. Supporting arguments from both sides with specific examples and details\n4. Finding common ground or conclusions (about 20% of the content)'
}

Format the response as a JSON object with two arrays: "speaker1Lines" and "speaker2Lines", 
containing each speaker's dialogue lines in order.

Example debate style (but generate new content):
Speaker 1: "While I see your point about X, I have to disagree because..."
Speaker 2: "That's interesting, but have you considered that..."`;

  try {
    const response = await openAI_WithRetry(prompt, wordCount, duration);
    
    if (!response.choices[0]?.message?.content) {
      throw new Error('No response received from Deep Seek API');
    }

    let scriptContent: PodcastScript;
    try {
      scriptContent = JSON.parse(response.choices[0].message.content) as PodcastScript;
    } catch (parseError) {
      console.error('Error parsing JSON response:', parseError);
      throw new Error('Failed to parse the generated script. Please try again.');
    }

    // Validate the script meets our requirements
    const totalLines = scriptContent.speaker1Lines.length + scriptContent.speaker2Lines.length;
    const totalWords = countWords(scriptContent);
    
    // For short formats, ensure we don't exceed the target length
    if ((duration === '<1' || duration === '>1') && totalWords > wordCount * 1.2) {
      console.log(`Script too long: ${totalLines} lines, ${totalWords} words. Trimming...`);
      return trimScript(scriptContent, duration);
    }
    
    // For YouTube and podcast platforms, ensure we have enough content
    if ((duration === '>8' || duration === '>15') && (totalLines < lineCount * 0.8 || totalWords < wordCount * 0.8)) {
      console.log(`Script too short: ${totalLines} lines, ${totalWords} words. Regenerating...`);
      // Try again with more explicit instructions
      return generatePodcastScript(topic, duration);
    }

    return scriptContent;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to generate script: ${error.message}`);
    }
    throw new Error('An unexpected error occurred while generating the script');
  }
}

// Helper function to count total words in the script
function countWords(script: PodcastScript): number {
  const allLines = [...script.speaker1Lines, ...script.speaker2Lines];
  return allLines.reduce((count, line) => count + line.split(/\s+/).length, 0);
}

// Helper function to trim scripts for short formats
function trimScript(script: PodcastScript, duration: string): PodcastScript {
  const trimmedScript: PodcastScript = {
    speaker1Lines: [],
    speaker2Lines: []
  };
  
  // For very short formats, we need to be strict about line count
  const maxLines = Math.min(script.speaker1Lines.length, script.speaker2Lines.length);
  const linesToKeep = duration === '<1' ? 2 : 3; // Keep 2 exchanges for <1 min, 3 for >1 min
  
  for (let i = 0; i < linesToKeep && i < maxLines; i++) {
    // Trim each line to be more concise if needed
    let line1 = script.speaker1Lines[i];
    let line2 = script.speaker2Lines[i];
    
    // Simplify sentences for very short formats
    if (duration === '<1') {
      line1 = simplifyForShortFormat(line1);
      line2 = simplifyForShortFormat(line2);
    }
    
    trimmedScript.speaker1Lines.push(line1);
    trimmedScript.speaker2Lines.push(line2);
  }
  
  return trimmedScript;
}

// Helper function to simplify text for short formats
function simplifyForShortFormat(text: string): string {
  // Remove filler words and phrases
  const fillerWords = [
    /\bactually\b/gi, 
    /\bbasically\b/gi, 
    /\bfrankly\b/gi, 
    /\bhonestly\b/gi, 
    /\bin my opinion\b/gi,
    /\bI believe that\b/gi,
    /\bI think that\b/gi,
    /\bas I was saying\b/gi,
    /\bto be honest\b/gi,
    /\bif you will\b/gi
  ];
  
  let simplified = text;
  fillerWords.forEach(word => {
    simplified = simplified.replace(word, '');
  });
  
  // Remove redundant phrases
  simplified = simplified.replace(/\b(it is|it's) (important|essential|crucial|vital) to (note|understand|recognize|remember) that\b/gi, '');
  
  // Simplify complex sentences
  const sentences = simplified.split('. ');
  if (sentences.length > 2) {
    // Keep only the first two sentences for very short formats
    simplified = sentences.slice(0, 2).join('. ') + '.';
  }
  
  // Remove extra spaces
  simplified = simplified.replace(/\s+/g, ' ').trim();
  
  return simplified;
}

// Retry logic for OpenAI calls
async function openAI_WithRetry(prompt: string, wordCount: number, duration: string, retries = 2) {
  let lastError;
  
  // Adjust temperature based on duration - higher for longer content to get more variety
  const temperature = duration === '>8' || duration === '>15' ? 0.8 : 0.7;
  
  for (let attempt = 0; attempt <= retries; attempt++) {
    try {
      return await openai.chat.completions.create({
        model: 'deepseek-chat',
        messages: [
          {
            role: 'system',
            content: `You are an expert podcast script writer who specializes in creating engaging debate-style conversations that explore different perspectives while maintaining professionalism and respect. 

${duration === '<1' ? 
  'For very short podcasts (<1 minute), you create extremely concise, impactful exchanges that capture the essence of a topic in just a few sentences. These are perfect for platforms like Instagram Reels and YouTube Shorts.' : 
  duration === '>1' ? 
  'For short podcasts (1-2 minutes), you create brief but meaningful exchanges that deliver value quickly while maintaining engagement. These are ideal for platforms like TikTok.' :
  duration === '>8' ? 
  'For longer podcasts (8-10 minutes), you create detailed, in-depth discussions with substantial dialogue, examples, and thorough explanations. Your scripts for YouTube are comprehensive and explore topics deeply.' :
  'For very long podcasts (15+ minutes), you create extensive, richly detailed conversations with multiple viewpoints, nuanced arguments, and comprehensive coverage of the topic. These are perfect for traditional podcast platforms.'
}`,
          },
          {
            role: 'user',
            content: prompt,
          },
        ],
        response_format: { type: 'json_object' },
        max_tokens: wordCount * 3, // Ensure enough tokens for the full response
        temperature: temperature,
      });
    } catch (error) {
      lastError = error;
      console.error(`API call attempt ${attempt + 1} failed:`, error);
      // Wait before retrying
      if (attempt < retries) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  }
  
  throw lastError;
}