import React from 'react'
import { ArrowLeft } from 'lucide-react'
import { Button } from './ui/button'
import { useNavigate } from 'react-router-dom'
import { Footer } from './Footer'

function Terms() {
  const navigate = useNavigate()
  const lastUpdated = '2/24/2025'

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <div className="absolute inset-0 -z-10 h-full w-full bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)]"></div>
      
      <header className="border-b bg-white/50 backdrop-blur-sm">
        <div className="container flex h-16 items-center">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => navigate('/')}
            className="flex items-center"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Generator
          </Button>
        </div>
      </header>

      <main className="container py-12 flex-1">
        <div className="max-w-4xl mx-auto">
          <div className="prose prose-slate mx-auto">
            <h1 className="text-4xl font-bold tracking-tight text-center mb-8">Terms and Conditions</h1>
            <p className="text-sm text-muted-foreground text-center mb-12">Last Updated: {lastUpdated}</p>

            <div className="space-y-8">
              <section>
                <p className="text-muted-foreground">
                  Welcome to podsoul.com (the "Website"), operated by podsoul.com ("Company," "we," "us," or "our"). 
                  These Terms and Conditions ("Terms") govern your access to and use of our services, including our 
                  AI podcast generator and subscription plans (collectively, the "Services"). By accessing or using 
                  our Services, you agree to be bound by these Terms. If you do not agree with these Terms, please 
                  do not use our Services.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
                <p>
                  By accessing or using the Website and Services, you affirm that you are at least 18 years old or 
                  have the consent of a parent or guardian. If you are accessing or using the Services on behalf of 
                  an organization, you represent that you have the authority to bind that organization to these Terms.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">2. Services Overview</h2>
                <p>
                  podsoul.com provides an AI-powered podcast generator that allows users to create podcasts through 
                  a subscription model. Users can choose between two subscription tiers: Basic and Pro. Each 
                  subscription plan provides users with a certain number of points that can be used to generate 
                  podcasts. Each podcast generation deducts one point from the user's account. Please note that 
                  the generated podcasts are not editable.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
                <p>To access certain features of our Services, you may be required to create an account. You agree to:</p>
                <ul className="list-disc pl-6 mt-2 space-y-2">
                  <li>Provide accurate, current, and complete information during the registration process.</li>
                  <li>Maintain the security of your password and account.</li>
                  <li>Notify us immediately of any unauthorized use of your account or any other breach of security.</li>
                  <li>Be responsible for all activities that occur under your account.</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">4. Subscription Plans and Payment</h2>
                <h3 className="text-xl font-medium mb-2">4.1 Subscription Tiers</h3>
                <ul className="list-disc pl-6 mb-4 space-y-2">
                  <li><strong>Basic Plan</strong></li>
                  <li><strong>Pro Plan</strong></li>
                </ul>

                <h3 className="text-xl font-medium mb-2">4.2 Payment Terms</h3>
                <p>
                  All payments for subscription plans are due in advance and are non-refundable. You authorize us 
                  to charge your chosen payment method for the subscription fees. In the event of a failure to make 
                  payment, we reserve the right to suspend or terminate your access to the Services.
                </p>

                <h3 className="text-xl font-medium mb-2 mt-4">4.3 Points System</h3>
                <p>
                  Each subscription plan provides users with a specific number of points. Points are deducted upon 
                  the generation of a podcast, and users cannot edit generated content. Points do not carry over 
                  beyond the subscription period unless explicitly stated.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">5. User Rights and Responsibilities</h2>
                <h3 className="text-xl font-medium mb-2">5.1 User Rights</h3>
                <p>You have the right to:</p>
                <ul className="list-disc pl-6 mt-2 mb-4 space-y-2">
                  <li>Access and use the Services in accordance with these Terms.</li>
                  <li>Cancel your subscription at any time, subject to the cancellation policy.</li>
                </ul>

                <h3 className="text-xl font-medium mb-2">5.2 User Responsibilities</h3>
                <p>You agree not to:</p>
                <ul className="list-disc pl-6 mt-2 space-y-2">
                  <li>Use the Services for any illegal or unauthorized purpose.</li>
                  <li>Impersonate any person or entity or misrepresent your affiliation with any person or entity.</li>
                  <li>Disrupt or interfere with the security, integrity, or performance of the Services.</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">6. Intellectual Property</h2>
                <p>
                  All content, trademarks, and other intellectual property rights associated with the Services are 
                  owned by the Company or our licensors. You are granted a limited, non-exclusive, non-transferable 
                  license to access and use the Services for personal, non-commercial purposes.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
                <p>
                  To the fullest extent permitted by law, the Company shall not be liable for any indirect, 
                  incidental, special, consequential, or punitive damages arising out of or related to your use 
                  of the Services. Our total liability to you for any claim arising out of or relating to these 
                  Terms or the Services shall not exceed the amount paid by you to us in the twelve (12) months 
                  preceding the claim.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">8. Dispute Resolution</h2>
                <h3 className="text-xl font-medium mb-2">8.1 Governing Law</h3>
                <p>
                  These Terms shall be governed by and construed in accordance with the laws of the State of Texas, 
                  United States, without regard to its conflict of law principles.
                </p>

                <h3 className="text-xl font-medium mb-2 mt-4">8.2 Binding Arbitration</h3>
                <p>
                  Any dispute arising out of or relating to these Terms or your use of the Services shall be 
                  resolved by binding arbitration in accordance with the rules of the American Arbitration 
                  Association. The arbitration shall take place in Houston, TX, and the arbitration award may be 
                  enforced in any court of competent jurisdiction.
                </p>

                <h3 className="text-xl font-medium mb-2 mt-4">8.3 Class Action Waiver</h3>
                <p>
                  You agree that any dispute resolution proceedings will be conducted only on an individual basis 
                  and not in a class, consolidated, or representative action.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">9. Changes to Terms</h2>
                <p>
                  We reserve the right to modify these Terms at any time. We will notify you of any changes by 
                  posting the new Terms on the Website. Your continued use of the Services after any such changes 
                  constitutes your acceptance of the new Terms.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">10. Contact Information</h2>
                <p>If you have any questions about these Terms, please contact us at:</p>
                <div className="mt-2">
                  <p>podsoul.com</p>
                  <p>podsoul.com@gmail.com</p>
                </div>
              </section>

              <hr className="my-8" />

              <p className="text-center text-sm text-muted-foreground">
                By using the Services, you acknowledge that you have read, understood, and agree to be bound by 
                these Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  )
}

export default Terms